<template>
  <div class="container">
    <el-descriptions title="工会信息" :column="4">
      <el-descriptions-item label="公会图标">
        <el-image
          style="width: 100px; height: 100px"
          :src="union.logo"
        ></el-image
      ></el-descriptions-item>
      <el-descriptions-item label="公会名称">{{
        union.name
      }}</el-descriptions-item>
      <el-descriptions-item label="公会电话">{{
        union.mobile
      }}</el-descriptions-item>
       <el-descriptions-item label="公会城市">{{
        union.city
      }}</el-descriptions-item>
      <el-descriptions-item label="服务区域">{{
        union.service_district
      }}</el-descriptions-item>
      <el-descriptions-item label="公会类目">{{
        union.category
      }}</el-descriptions-item>
      <el-descriptions-item label="公会人数"
        >{{ union.number }}/{{ union.max_number }}</el-descriptions-item
      >
      <el-descriptions-item label="接单数">{{
        union.order_number
      }}</el-descriptions-item>
      <el-descriptions-item label="状态">
        <el-tag size="small" type="info" v-if="union.status === 0"
          >待审核</el-tag
        >
        <el-tag size="small" type="success" v-if="union.status === 1"
          >正常</el-tag
        >
        <el-tag size="small" type="danger" v-if="union.status === 2"
          >冻结</el-tag
        ></el-descriptions-item
      >
      <el-descriptions-item label="公会介绍">{{
        union.introduce
      }}</el-descriptions-item>
    </el-descriptions>

    <div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="工会成员" name="member">
          <memberList ref="member"></memberList>
        </el-tab-pane>
          <el-tab-pane label="公会成员记录" name="apply">
          <applyList ref="apply"></applyList>
        </el-tab-pane>
          <el-tab-pane label="公会订单" name="order">
          <orderList ref="order"></orderList>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import applyList from './components/applyList.vue';
import memberList from './components/memberList.vue';
import orderList from './components/orderList.vue';

export default {
    components:{
        memberList,
        applyList,
        orderList
    },
  data() {
    return {
      id: "",
      union: {},
      activeName: "member",
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.getInfo();
  },

  methods: {
    getInfo() {
      this.$axios({
        url: "/user/tradeUnion/findById",
        params: {
          id: this.id,
        },
      }).then((res) => {
        this.union = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>