<template>
  <div class="">
    <el-form :inline="true">
         <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
          <el-option v-for="(item,i) in typeMap" :key="i" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList(1)">搜索</el-button>
        </el-form-item>
      </el-form>
   
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #status="{ row }">
        <div>
          {{getLabel("typeMap",row.status) }}
        </div>
      </template>
      <template #name="{ row }">
        <div v-if="row.map">
          {{ row.map.coach.real_name }}
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
         { name: "订单", value: "title" },
        { name: "教练", value: "name" , type: "custom" },
       
        { name: "状态", value: "status", type: "custom" },
         { name: "类目", value: "categories" },
          { name: "地址", value: "address" },
        { name: "时间", value: "create_time" },
      ],
      Total: 0,
      status: "",
      name:'',
      id: "",
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
       typeMap: [
        {
          label: "待开课",
          value: 6,
        },
         {
          label: "已开课",
          value: 7,
        },
         {
          label: "已完成",
          value: 8,
        }
      ],
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.getList(1);
  },

  methods: {
    load() {
      if (this.DataList.length == 0) {
        this.getList(1);
      }
    },
    getLabel(list, v) {
      for (let index = 0; index < this[list].length; index++) {
        if (this[list][index].value === v) {
          return this[list][index].label;
        }
      }
    },
   
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/userCourseNeed/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          union_id: this.id,
          create_type: 1,
          status:this.status || null,
		type: 'order',
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>