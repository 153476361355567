<template>
  <div class="">
    <el-form :inline="true">
      <el-form-item>
        <el-input v-model="name" placeholder="教练姓名"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
        <el-form-item>
        <el-button type="primary" @click="addShow = true">邀请</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #role="{ row }">
        <div>
          {{ row.role == "owner" ? "会长" : "教练" }}
        </div>
      </template>
    </auto-table>

    <el-dialog
      title="添加成员"
      :visible.sync="addShow"
      width="50%"
    >
      <el-form :inline="true">
      <el-form-item>
        <el-input v-model="keyWord" placeholder="教练姓名或电话"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getCoach">搜索</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="aDataList"
      :option="aOption"
      :total="aTotal"
      @changePage="getCoach"
    >
      <template #handle="{ row }">
        <div>
          <el-button type="primary" @click="addJoin(row)">邀请加入</el-button>
        </div>
      </template>
    </auto-table>

    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      name: "",
      Option: [
        { name: "教练", value: "name" },
        { name: "类型", value: "role", type: "custom" },
        { name: "加入时间", value: "create_time" },
      ],
      Total: 0,
      type: "",
      id: "",
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),

      addShow:false,
      aDataList: [],
      keyWord: "",
      aOption: [
        { name: "教练", value: "real_name" },
        { name: "电话", value: "phone" },
        { name: "操作", value: "handle", type: "custom" },
      ],
      aTotal: 0,
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.getList(1);
  },

  methods: {
    addJoin(row){
        let data ={
          coach_id:row.id,
          name:row.real_name,
          union_id:this.id,
          type:'invite_add',
          handler_id:this.adminInfo.id,
          handler_name:this.adminInfo.name
        }
        this.$post('/user/applyUnionRecord/inviteCoach',data).then(res=>{
          this.addShow = false
          this.getList(1)
        }).catch(err=>{
          this.$message(err.data.message)
        })
    },
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/unionMember/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          union_id: this.id,
          name: this.name || null,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },

       getCoach() {
        let data = {}
        const numberReg = /^\d/
        if (this.keyWord) {
          numberReg.test(this.keyWord)?(data.phone = this.keyWord) : (data.real_name = this.keyWord)
          let url = "/user/coach/queryManagerListPage";
          this.$axios
            .get(url, {
              params: data,
            })
            .then((res) => {
              this.aDataList = res.data.data.rows;
               this.aTotal = res.data.data.total;
            });
        }
      },
  },
};
</script>

<style lang="scss" scoped>
</style>